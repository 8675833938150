import React, { Component } from "react";

class MiniPreloader extends Component {
    render() {
        return (
           
                    <div className="d-flex justify-content-center row align-items-center text-center">
                        <div className="col">
                            <div
                                className="spinner-border text-primary"
                                style={{ width: "1rem", height: "1rem" }}
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
           
        )
    }
}

export default MiniPreloader;
