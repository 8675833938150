module.exports = {
    app_title: "Admin Dashboard",
    tokenKey: "tntpkcc_jwt",
    lang_id: "en",
    cipher_crypt: "09xuaqpik",
    authBasic: {
        usr:"OHL1-Backend",
        pwd:"$2b$10$PYVB07qecio7hBGl.EszlQsLsO/yf1mxLyP4IqBx/GzibyZnUuEDu0980"
    },
    prefixes: {
        merchant: "_q5mct_",
        user: "_q5usr_",
        category: "_q5ctgr_"
    }
};