import { COMPLETE_UPDATE_USER, GET_ERRORS } from "./types";
import jwtDecode from "jwt-decode";
import config from "../config";
import rest from "../utils/rest";
import { formLoadingStart, formLoadingFinish } from "./modal";
import { setCurrentUser } from "./auth";
import { cacheStore, setAuthToken } from "../utils/helpers";

export const updateUser = (id, userData, current = false) => dispatch => {
  dispatch(formLoadingStart());
  return new Promise((resolve, reject) => {
    rest()
      .post("employee/edit/" + id, userData)
      .then(res => {
        dispatch({
          type: COMPLETE_UPDATE_USER
        });

        if (current) {
          const { token } = res.data;
          // Set token to ls
          cacheStore().setItem(config.tokenKey, token);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          let decoded = jwtDecode(token);
          // Set current user
          dispatch(setCurrentUser(decoded));
        }

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });

        reject(err);
      })
      .then(function () {
        dispatch(formLoadingFinish());
      });
  });
};

export const changePassword = userData => dispatch => {
  dispatch(formLoadingStart());
  return new Promise((resolve, reject) => {
    rest()
      .post("profile/change.password", userData)
      .then(res => {
        dispatch({
          type: COMPLETE_UPDATE_USER
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });

        reject(err);
      })
      .then(function () {
        dispatch(formLoadingFinish());
      });
  });
};
