import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    blacklist,
    removeBlacklistIdentity
} from "../../actions/app";
import {
    createNotification,
    pageTitle,
    isStaff,
    currency,
    monthData
} from "../../utils/helpers";
import { Link, useHistory } from "react-router-dom"
import CryptoJS from "crypto-js"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty, has, isNil, merge, omit, pullAt } from "lodash";
import classnames from "classnames"
import Styled from "styled-components"
import Preloader from "../ui/Preloader"
import BlacklistPhoneTable from "../tables/BlacklistPhoneTable";
import DocumentException from "../common/DocumentException";
import { useMediaQuery } from "react-responsive"

const MySwal = withReactContent(Swal);

const Styles = Styled.div`
    table {
        th
    }
`

const BlacklistTable = (props) => {
    const history = useHistory()
    const curDate = new Date()
    const mo = curDate.getMonth() + 1
    const yr = curDate.getFullYear()
    const curMo = mo < 10 ? '0' + mo : mo
    const urlParams = new URLSearchParams(props.location.search)
    let key2

    if( ! urlParams.get('state') ) {
        key2 = 'email'
    } else {
        key2 = urlParams.get('state')
    }
    
    const [key, setKey] = useState(key2)
    const [filter, setFilter] = useState(false)
    const [states, setStates] = useState({
        error: null,
        filters: [],
        key:key2,
        args: {
            type:key2,
            offset:0,
            limit:50
        },
        loading: false,
        page: 1,
        pages: 1,
    })

    useEffect(()=>{
        onFetchData()
    },[])

    useEffect(() => {
        if( filter ) {
            onFetchData()
        }
    },[filter, states.args])

    function onFetchData() {
        setStates({...states, loading: true})
        props.blacklist(states.args).then(res => {
            const count = res.data.count
            const pages = Math.ceil(count / states.args.limit)
            setFilter(false)
            setStates({...states, loading:false, pages:pages})
        }).catch(err => {
            setFilter(false)
            createNotification("error", "Failed retrieve data for this user");
        })
    }

    function onShowPage(e) {
        setKey(e.target.value)
        setStates({...states,args:{offset:0,limit:50,type:e.target.value}})
        setFilter(true)
        //history.replace(`/blacklisted?state=${e.target.value}`)
    }
    
    function onRemoveBlacklist(id, label) {
        let field
        if( key == 'phone_no' ) {
            field = `Phone Number (${label})`
        } else if( key  == 'email' ) {
            field = `Email Address (${label})`
        } else if( key  == 'ip_address' ) {
            field = `IP Address (${label})`
        }

        MySwal.fire({
            title: `Are you sure want to revoke this ${field}?`,
            showCancelButton: true
          }).then(prompt => {
            if (has(prompt, 'value')) {
              MySwal.fire({
                title: <p>Loading...</p>,
                onOpen: () => {
                  MySwal.showLoading();
                  props.removeBlacklistIdentity(id).then(res => {
                    MySwal.fire({
                        type: "success",
                        title: "Great!",
                        text: `${field} is successfully revoked`,
                        timer: 2000
                    });
                    onFetchData(key)
                  }).catch(err => {
                    const resp = err.response
                    MySwal.fire({
                      type: "error",
                      title: "Error Exception!",
                      text: has(resp, "data.messages")
                        ? resp.data.messages
                        : "Something went wrong"
                    });
                  })

                }
              });
            }
          });
    }

    function onPageNext(e, number=null) {
        e.preventDefault()
        const { list } = props.apps
        let pageIndex

        if( number ) {
            pageIndex = number
        } else {
            pageIndex = states.page + 1
        }
        
        const offset = ( pageIndex - 1 ) * states.args.limit
        const args = {
            offset:offset,
            limit:states.args.limit
        }

        if( offset < list.count) {
            setStates({
                ...states,
                page:pageIndex,
                args:{...states.args, offset:offset}
            })
            onFetchData(key)
        }
    }

    function onPageNumber(number) {
        const { list } = props.apps
        const pageIndex = number
        const offset = ( pageIndex - 1 ) * states.args.limit
        const args = {
            offset:offset,
            limit:states.args.limit
        }

        if( offset < list.count) {
            setStates({
                ...states,
                page:pageIndex,
                args:{...states.args, offset:offset}
            })
            onFetchData(key)
        }
    }

    function onPagePrev(e) {
        e.preventDefault()
        const { list } = props.apps
        if( states.page == 1 ) 
            return

        const pageIndex = states.page - 1;
        const offset = ( pageIndex - 1 ) * states.args.limit
        const args = {
            offset:offset,
            limit:states.args.limit
        }

        if( offset < list.count) {
            setStates({
                ...states,
                page:pageIndex,
                args:{...states.args, offset:offset}
            })
            onFetchData(key)
        }        
    }

    function onRefresh() {
        setStates({
            ...states,
            page:1,
            args:{...states.args, offset:0}
        })
        onFetchData(key)
    }

    function onClear(e) {
        let a = key
        pullAt(states.args, [`${a}`]);

        setStates({
            ...states,
            page:1,
            args:{...states.args, offset:0}
        })
        onFetchData(a)
    }

    const { blacklist } = props.apps;
    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isPhone = useMediaQuery({maxDeviceWidth:414,orientation:"portrait"})

    if( isEmpty(blacklist) ) {
        return  null
    }
    
    return (
        <>
            <DocumentException
                title={pageTitle("Blacklist")}
                className="blacklistedPage"
                history={props.history}
                url={props.location.pathname}
            >
                <div className="container">
                <div className="row justify-content-md-center mt-3">
                    <div className="col-sm-12 col-lg-12 mb-3">
                    <div className="card mb-3">
                        <div className="card-header text-center">
                            <h5>Blacklisted {key == 'phone_no' ? 'Phone Number' : 'Email'}</h5>
                        </div>
                        <div className="card-body">
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-12" style={{ minHeight: 200 }}>
                                        
                                        <div className="row mb-3 justify-content-between mt-4">
                                            <div className="col col-sm-6 col-lg-6">
                                                Total {blacklist.data.count} record(s) <br /> <span style={{ lineHeight:2 }}> {blacklist.data.count > 0 && <span>Page: {states.page} of {states.pages} </span>}</span>                        
                                            </div>
                                            
                                            <div className={classnames("text-right col", {
                                                    'col-4':isStaff(props.user),
                                                    'col-sm-5 col-md-5':!isStaff(props.user)
                                                })}>
                                                
                                                    <Link className="col col-md-4 col-sm-10 btn btn-outline-info" style={{marginTop:0}} to={'/dashboard'}>Back to List</Link>

                                                    <select name="page" id="" onChange={onShowPage} style={{display:"inline-block",marginTop:0}} className="form-control mt-2 col ml-md-3 ml-0 col-lg-7" value={key}>
                                                        <option value="email">Blacklist Email</option>
                                                        <option value="phone_no">Blacklist Phone Number</option>
                                                        <option value="ip_address">Blacklist IP Address</option>
                                                    </select>
                                               
                                            </div>
                                        </div>

                                        <div className="row mb-3 justify-content-between mt-3" style={{fontSize:13}}>
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form className="form-inline">
                                                            <div className="row w-100">
                                                                <div className="form-group mb-2 col-12">
                                                                    <label htmlFor="" className="font-weight-bold">Filter by : </label>
                                                                </div>

                                                                {key == 'email' && <div className={classnames("form-group", {
                                                                    "col-12":isPhone,
                                                                    "col-4":isDesktopOrLaptop
                                                                })}>
                                                                    <input type="email" onChange={(e)=>{
                                                                        if( ! isEmpty(e.target.value) ) {
                                                                            setStates({
                                                                                ...states,
                                                                                args:{
                                                                                    ...states.args, 
                                                                                    type:"email",
                                                                                    email:e.target.value
                                                                                }
                                                                            })
                                                                            setFilter(true)
                                                                        }
                                                                        
                                                                    }} className="form-control w-100" id="email" placeholder="Enter Email" />
                                                                </div>}

                                                                {key == 'phone_no' && <div className={classnames("form-group", {
                                                                    "col-12":isPhone,
                                                                    "col-4":isDesktopOrLaptop
                                                                })}>
                                                                    <input type="text" onChange={(e)=>{
                                                                        if( ! isEmpty(e.target.value) ) {
                                                                            setStates({
                                                                                ...states,
                                                                                args:{
                                                                                    ...states.args,
                                                                                    type:"phone_no",
                                                                                    phone_no:e.target.value
                                                                                }
                                                                            })
                                                                            setFilter(true)
                                                                        }
                                                                        
                                                                    }} className="form-control w-100" id="phone_no" placeholder="Enter Phone No." />
                                                                </div>}

                                                                {key == 'ip_address' && <div className={classnames("form-group", {
                                                                    "col-12":isPhone
                                                                })}>
                                                                    <input type="text" onChange={(e)=>{
                                                                        if( ! isEmpty(e.target.value) ) {
                                                                            setStates({
                                                                                ...states,
                                                                                args:{
                                                                                    ...states.args, 
                                                                                    type:"ip_address",
                                                                                    ip_address:e.target.value
                                                                                }
                                                                            })
                                                                        }
                                                                        
                                                                    }} className="form-control w-100" id="ip_address" placeholder="Enter IP Address" />
                                                                </div>}
                                                                
                                                                <div className="col col-8">
                                                                    <button className="btn btn-outline-secondary" type="button" onClick={onClear}>Clear</button>
                                                                </div>
                                                               
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>                
                                            </div> 
                                        </div>


                                        {states.loading && <Preloader />}
                                        {!states.loading && ! isEmpty(key) && ! isEmpty(blacklist.data) && <div className="table-responsive">
                                            <BlacklistPhoneTable 
                                                field={key}
                                                list={blacklist.data.list} 
                                                loading={states.loading} 
                                                count={blacklist.data.count} 
                                                args={states.args}
                                                page={states.page}
                                                pages={states.pages} 
                                                onPagePrev={onPagePrev} 
                                                onPageNumber={onPageNumber} 
                                                onPageNext={onPageNext}
                                                onRemoveBlacklist={onRemoveBlacklist}
                                            />
                                        </div>}
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </DocumentException>
        </>
    )
}

BlacklistTable.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
    apps: state.apps,
    user: state.auth.user.user,
});

export default connect(
    mapStatetoProps,
    { blacklist, removeBlacklistIdentity }
)(BlacklistTable); 
