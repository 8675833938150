import _ from "lodash";
import lang from "../langs";
import { validateEmail } from "../utils/helpers";

export const addUserValidate = userData => {
  return new Promise((resolve, reject) => {
    let output = {};

    if (_.isEmpty(userData.name)) {
      output.name = lang("required.name");
    }

    if (_.isEmpty(userData.no_hp)) {
      output.phone = lang("required.phone");
    }

    if (_.isEmpty(userData.email)) {
      output.email = lang("required.email");
    } else {
      if (!validateEmail(userData.email)) {
        // output.email = 'Alamat email tidak valid'
      }
    }

    if (_.isEmpty(userData.role)) {
      output.role = lang("required.role");
    }

    if (_.isEmpty(userData.begun_at)) {
      output.begun_at = lang("required.begun_at");
    }

    if (_.isEmpty(userData.exited_at)) {
      output.exited_at = lang("required.exited_at");
    }

    if (_.isEmpty(userData.password)) {
      output.password = lang("required.password");
    }

    if (_.isEmpty(userData.confirm_password)) {
      output.confirm_password = lang("required.confirm_password");
    }

    if (
      !_.isEmpty(userData.password) &&
      !_.isEmpty(userData.confirm_password)
    ) {
      if (userData.password != userData.confirm_password) {
        output.confirm_password =
          "Password confirmation is not matched with password";
      }
    }

    if (_.isEmpty(output)) {
      resolve(userData);
    } else {
      reject(output);
    }
  });
};

export const updateUserValidate = userData => {
  return new Promise((resolve, reject) => {
    let output = {};

    if (_.isEmpty(userData.name)) {
      output.name = lang("required.name");
    }

    if (_.isEmpty(userData.no_hp)) {
      output.phone = lang("required.phone");
    }

    if (_.isEmpty(userData.email)) {
      output.email = lang("required.email");
    } else {
      if (!validateEmail(userData.email)) {
        // output.email = 'Alamat email tidak valid'
      }
    }

    if (_.isEmpty(userData.role)) {
      output.role = lang("required.role");
    }

    if (_.isEmpty(userData.begun_at)) {
      output.begun_at = lang("required.begun_at");
    }

    if (_.isEmpty(userData.exited_at)) {
      output.exited_at = lang("required.exited_at");
    }

    if (_.isSet(userData.password)) {
      if (_.isEmpty(userData.password)) {
        output.password = lang("required.password");
      }

      if (_.isEmpty(userData.confirm_password)) {
        output.confirm_password = lang("required.confirm_password");
      }

      if (
        !_.isEmpty(userData.password) &&
        !_.isEmpty(userData.confirm_password)
      ) {
        if (userData.password != userData.confirm_password) {
          output.confirm_password =
            "Password confirmation is not matched with password";
        }
      }
    }

    if (_.isEmpty(output)) {
      resolve(userData);
    } else {
      reject(output);
    }
  });
};

export const updateProfileValidate = userData => {
  return new Promise((resolve, reject) => {
    let output = {};

    if (_.isEmpty(userData.name)) {
      output.name = lang("required.name");
    }

    if (_.isEmpty(userData.no_hp)) {
      output.phone = lang("required.phone");
    }

    if (_.isEmpty(userData.email)) {
      output.email = lang("required.email");
    } else {
      if (!validateEmail(userData.email)) {
        // output.email = 'Alamat email tidak valid'
      }
    }

    if (_.isEmpty(output)) {
      resolve(userData);
    } else {
      reject(output);
    }
  });
};

export const changePasswordValidate = userData => {
  return new Promise((resolve, reject) => {
    let output = {};

    if (_.isEmpty(userData.old_password)) {
      output.old_password = lang("required.old_password");
    }

    if (_.isEmpty(userData.new_password)) {
      output.password = lang("required.password");
    }

    if (_.isEmpty(userData.new_password_confirmation)) {
      output.confirm_password = lang("required.confirm_password");
    }

    if (
      !_.isEmpty(userData.password) &&
      !_.isEmpty(userData.confirm_password)
    ) {
      if (userData.password != userData.confirm_password) {
        output.confirm_password =
          "Password confirmation is not matched with password";
      }
    }

    if (_.isEmpty(output)) {
      resolve(userData);
    } else {
      reject(output);
    }
  });
};
