import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types";
import {
    fetchApplications,
    putNote,
    trashApp,
    putWorkStatus,
    sendFollowupEmail,
    addBlacklistIdentity,
    fetchWebsites
} from "../../actions/app";
import {
    createNotification,
    pageTitle,
    isStaff,
    currency,
    monthData,
    yearData
} from "../../utils/helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty, has, mapValues, isNil, omit, omitBy, upperCase, map } from "lodash";
import StaffTable from "../tables/StaffTable"
import AdminTable  from "../tables/AdminTable"
import Styled, { ThemeConsumer } from "styled-components"
import clsx from "clsx"
import { useMediaQuery } from "react-responsive"
import Preloader from "../ui/Preloader"

const MySwal = withReactContent(Swal);
const Styles = Styled.div`
    table {
        th
    }
`
const AppTable = (props) => {
    const curDate = new Date() 
    const mo = curDate.getMonth() + 1
    const yr = curDate.getFullYear()
    const [states, setStates] = useState({
        error: null,
        filters: [],
        websites:null,
        args: {
            offset:0,
            limit:30,
            yr:yr,
            mo:mo<10?`0${mo}`:mo
        },
        loading: false,
        loadingNote:false,
        sending:false,
        note: '',
        page: 1,
        pages: 1,
        tab:'active'
    })
    const [loading, setLoading] = useState(true)
    const [pages, setPages] = useState(1)
    const [tab, setTab] = useState("active")
    const [websites, setWebsites] = useState(null)
    const history = useHistory()
 
   
    const curMo = mo < 10 ? '0' + mo : mo
    
    useEffect(() => {
        setLoading(true)
        if (isStaff(props.user)) {
            setStates({...states, args:{...states.args, status:'pending'}})
            onFetchData('loading')
        } else {
            fetchWebsite()
            onFetchData('loading')
        }
    }, []);

    useEffect(() => {
        onFetchData()
    },[states.args])

    function onFetchData(loader='loading') {
        setLoading(true)
        props.fetchApplications(states.args).then(res => {
            const count = res.count
            const pages = Math.ceil(count / states.args.limit)
            //setStates({ ...states, [loader]: false})
            setLoading(false)
            setPages(pages)
        }).catch(err => {
            createNotification("error", "Failed retrieve data for this user");
        })
    }

    function fetchWebsite() {
        props.fetchWebsites().then(res=>{
            console.log(res)
            setWebsites(res.data)
        })
    }

    function onSubmitNote(id, note, e) {
        props.putNote({
            id: id,
            action: "putNote",
            note: note
        }).then(res => {
            setStates({ ...states, loadingNote: true })
            props.fetchApplications(states.args).then(res => {
                setStates({ ...states, loadingNote: false })
            }).catch(err => {
                createNotification("error", "Failed retrieve data for this user");
            })
        })
    }

    function onDelete(id) {
        MySwal.fire({
            title: "Are you sure want to permanent delete this application?",
            showCancelButton: true
          }).then(prompt => {
            if (has(prompt, 'value')) {
              MySwal.fire({
                title: <p>Loading...</p>,
                onOpen: () => {
                  MySwal.showLoading();
                  props.trashApp(id, {}).then(res => {
                    MySwal.fire({
                        type: "success",
                        title: "Great!",
                        text: "Application has been approved",
                        timer: 2000
                    });
                    onFetchData('loading')
                  }).catch(err => {
                    MySwal.fire({
                      type: "error",
                      title: "Error Exception!",
                      text: has(err, "data")
                        ? err.data.messages
                        : "Something went wrong"
                    });
                  })
                }
              });
            }
          });
    }

    async function onSetBlacklistPhone(id, opts={}) {
        /* inputOptions can be an object or Promise */
        const inputOptions = new Promise((resolve) => {
            setTimeout(() => {
                let data = {
                    'phone_no': 'Phone Number',
                    'email': 'Email',
                    'ip_address': 'IP Address'
                }
                let a = mapValues(data, function(o,k){ return !opts[k] ? o : null })
                //console.log(omitBy(a, isNil))
                resolve(omitBy(a, isNil))
            }, 1000)
        })
        
        const {value:label} = Swal.fire({
            title: 'Please select one to blacklist',
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to choose phone number or email!'
                }
            }
        }).then(obj=>{
            if (obj.value) {
                MySwal.showLoading();
                props.addBlacklistIdentity(id, {type:obj.value}).then(res => {
                    let field
                    if( obj.value == 'email') {
                        field = 'Email'
                    } else if( obj.value == 'phone_no') {
                        field = 'Phono No'
                    } else if( obj.value == 'ip_address') {
                        field = 'IP Address'
                    }
                    MySwal.fire({
                        type: "success",
                        title: "Great!",
                        text: `${field} is successfully blacklisted`,
                        timer: 2000
                    });
                    onFetchData('loading')
                    }).catch(err => {
                        const resp = err.response
                        MySwal.fire({
                            type: "error",
                            title: "Error Exception!",
                            text: has(resp, "data.messages")
                                ? resp.data.messages
                                : "Something went wrong"
                        });
                    })
            }
        })
    }

    function onPageNext(e, number=null) {
        e.preventDefault()
        const { list } = props.apps

        let pageIndex

        if( number ) {
            pageIndex = number
        } else {
            pageIndex = states.page + 1
        }
        
        const offset = ( pageIndex - 1 ) * states.args.limit
        const args = {
            offset:offset,
            limit:states.args.limit
        }

        if( offset < list.count) {
            setStates({
                ...states, 
                page:pageIndex,
                args:{
                    ...states.args,
                    offset:offset
                }
            })
            onFetchData('loading')
        }
        
    }

    function onPageNumber(number) {
        const { list } = props.apps
        const pageIndex = number

        const offset = ( pageIndex - 1 ) * states.args.limit
        const args = {
            offset:offset,
            limit:states.args.limit
        }

        if( offset < list.count) {
            setStates({
                ...states, 
                page:pageIndex,
                args:{
                    ...states.args,
                    offset:offset
                }
            })
            onFetchData('loading')
        }
        
    }

    function onPagePrev(e) {
        e.preventDefault()
        const { list } = props.apps
        if( states.page == 1 ) 
            return

        const pageIndex = states.page - 1;
        const offset = ( pageIndex - 1 ) * states.args.limit
        const args = {
            offset:offset,
            limit:states.args.limit
        }

        if( offset < list.count) {
            setStates({
                ...states, 
                page:pageIndex,
                args:{
                    ...states.args,
                    offset:offset
                }
            })
            onFetchData('loading')
        }
    }

    function onSetWorkStatus(id, status) {
        let title
        if( status == 'done') {
            title = 'Are you sure want to set complete this application?'
        } else if ( status == 'waiting') {
            title = 'Are you sure want to set undone this application?'
        }

        props.putWorkStatus({
            id: id,
            action: "putWorkStatus",
            status: status
        }).then(res => {
            MySwal.fire({
                type: "success",
                title: "Great!",
                text: "Status is updated",
                timer: 2000
            });
            onFetchData('loading')
        }).catch(err => {
            MySwal.fire({
                type: "error",
                title: "Warning!",
                text: has(err, "data")
                    ? err.data.messages
                    : "Something went wrong"
            });
        })
    }

    function setNote(e) {
        setStates({ ...states, note: e.target.value })
    }

    function showActiveList(e) {
        e.preventDefault()
        setStates({
            ...states,
            page:1,
            tab:"active",
            args:{
                ...states.args,
                offset:0,
                work_status:'pending'
            }
        })
        onFetchData('loading')   
    }

    function showDoneList(e) {
        e.preventDefault()

        setStates({
            ...states,
            page:1,
            tab:"done",
            args:{
                ...states.args,
                offset:0,
                work_status:'done'
            }
        })
        onFetchData('loading')
    }

    function onShowPage(e) {
        history.push(`/blacklisted?state=${e.target.value}`)
    }

    function onRefresh() {
        setStates({
            ...states,
            page:1,
            args:{
                ...states.args,
                offset:0,
            }
        })
        onFetchData('loading')
    }

    function onSendEmail(id, email) {
        setStates({
            ...states,
            sending:id
        })

        MySwal.fire({
            title: <p>Sending to {email} </p>,
            onOpen: () => {
                MySwal.showLoading();
                props.sendFollowupEmail(id)
                    .then(res=>{
                        MySwal.fire({
                            type: "success",
                            title: "Great!",
                            text: "Email is sent",
                        });
                    })
                    .catch(err=>{
                        MySwal.fire({
                            type: "error",
                            title: "Warning!",
                            text: has(err, "data")
                                ? err.data.messages
                                : "Something went wrong"
                        });
                    
                    })
                    .finally(()=>{
                        props.fetchApplications(states.args)
                        setStates({
                            ...states,
                            sending:false
                        })
                    })
            }
          });
    }

    const { list } = props.apps;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const isPhone = useMediaQuery({maxDeviceWidth:414,orientation:"portrait"})

    const isDesktopOrLaptop = useMediaQuery({
        minDeviceWidth:767
    })

    if( isEmpty(list) ) {
        return  null
    }
       
    return (
        <>
            {isPhone && isStaff(props.user) && <div className="row">
                <div className="col-12">
                    <ul className="nav nav-pills">
                        <li style={{lineHeight:2.4}}>Filter by </li>
                        <li className="nav-item">
                            <a className={clsx('nav-link', {
                                'active':states.tab === 'active'
                            })} href="#" onClick={showActiveList}>Active</a>
                        </li>
                        <li className="nav-item">
                            <a className={clsx('nav-link', {
                                'active':states.tab === 'done'
                            })} href="#" onClick={showDoneList}>Done</a>
                        </li>
                    </ul>
                </div>
            </div>}

            <div className="row mb-3 justify-content-between mt-4">
                <div className={clsx("col", {
                    "col-lg-6 col-sm-6":!isStaff(props.user),
                    "col-lg-4 col-sm-4":isStaff(props.user)
                })}>
                    Total {list.count} record(s) <br /> <span style={{ lineHeight:2 }}> {list.count > 0 && <span>Page: {states.page} of {pages} </span>}</span>                        
                </div>

                {!isPhone && isStaff(props.user) && <div className="col-4">
                    <ul className="nav nav-pills">
                        <li style={{lineHeight:2.4}}>Filter by </li>
                        <li className="nav-item">
                            <a className={clsx('nav-link', {
                                'active':states.tab === 'active'
                            })} href="#" onClick={showActiveList}>Active</a>
                        </li>
                        <li className="nav-item">
                            <a className={clsx('nav-link', {
                                'active':states.tab === 'done'
                            })} href="#" onClick={showDoneList}>Done</a>
                        </li>
                    </ul>
                </div>}

                <div className={clsx("text-right col", {
                    'col-4':isStaff(props.user) && isDesktopOrLaptop,
                    'col-6':isStaff(props.user) && isPhone,
                    'col-sm-5 col-md-5':!isStaff(props.user)
                })}>
                    <button className="btn btn-outline-info col col-md-4 col-sm-10" 
                    style={{marginTop:0}} 
                        onClick={onRefresh}>Refresh</button>
                    <select name="page" id="" onChange={onShowPage} 
                        className={clsx("form-control col mt-2 col-md-7 col-lg-7",{"ml-2":isDesktopOrLaptop})} 
                        style={{display:"inline-block"}}>
                        <option value="">--Choose one--</option>
                        <option value="email" >Blacklist Email</option>
                        <option value="phone_no">Blacklist Phone Number</option>
                        <option value="ip_address">Blacklist IP Address</option>
                    </select>
                </div>
            </div>

            <div className="row mb-3 justify-content-between mt-3" style={{fontSize:13}}>
                <div className="col-12">
                    <div className="card">
                        <div className={clsx("card-body p-3")}>
                            <div className="form-group mb-0">
                                <label htmlFor="" className="font-weight-bold">Filter by : </label>
                            </div>
                            <form className="form-inline">
                                <div className={clsx("row", {"w-100":isDesktopOrLaptop})}>
                                    <div className={clsx("form-group", {
                                        "col-12":isPhone,
                                        "col-4":isDesktopOrLaptop,
                                    })}>
                                        <input type="email" onChange={(e)=>{
                                            setStates({
                                                ...states, 
                                                args:{...states.args, email:e.target.value
                                            }})
                                           // onFetchData()
                                        }} className={clsx("form-control", {
                                            "form-control-lg":isPhone,
                                            'w-100':isDesktopOrLaptop
                                        })} id="email" placeholder="Email" />
                                    </div>

                                    <div className={clsx("form-group", {
                                            "col-6":isPhone,
                                            "col-4":isDesktopOrLaptop,
                                        })}>
                                        <input type="text" onChange={(e)=>{
                                            setStates({
                                                ...states, 
                                                args:{...states.args, phone_no:e.target.value
                                            }})
                                            //onFetchData()
                                        }} className={clsx("form-control", {
                                            "form-control-lg":isPhone,
                                            'w-100':isDesktopOrLaptop
                                        })} id="phone_no" placeholder="Phone No." />
                                    </div>

                                    <div className={clsx("form-group", {
                                         'col-6 ml-0':isPhone,
                                         'col-4':isDesktopOrLaptop,
                                    })}>
                                        <input type="text" onChange={(e)=>{
                                            setStates({
                                                ...states, 
                                                args:{...states.args,ip_address:e.target.value
                                            }})
                                            //onFetchData()
                                        }} className={clsx("form-control", {
                                            "form-control-lg":isPhone,
                                            'w-100':isDesktopOrLaptop
                                        })} id="ip_address" placeholder="IP Address" />
                                    </div>

                                    {isStaff(props.user) && <div className={clsx("form-group mt-3", {
                                        'col-6':isPhone,
                                        'col-3':isDesktopOrLaptop
                                    })}>
                                        <select defaultValue={curMo} onChange={(e)=>{
                                            if( isEmpty(e.target.value) ) {
                                                let args = omit(states.args, ['mo'])
                                                setStates({
                                                    ...states, 
                                                    args:args
                                                })
                                            } else {
                                                setStates({
                                                    ...states, 
                                                    args:{...states.args,mo:e.target.value
                                                }})
                                            }
                                            //onFetchData()
                                        }} name="mo" id="" className={clsx("form-control", {
                                            "form-control-lg":isPhone,
                                            "w-100":isDesktopOrLaptop
                                        })}>
                                            <option value="">All</option>
                                            {monthData().map((mo,i)=> <option key={i} value={mo.key}>{mo.label}</option>)}
                                        </select>
                                    </div>}

                                    {isStaff(props.user) && <div className={clsx("form-group mt-3", {
                                        'col-6':isPhone,
                                        'col-3':isDesktopOrLaptop
                                    })}>
                                        <select value={states.yr} onChange={(e)=>{
                                            setStates({
                                                ...states, 
                                                args:{...states.args,yr:e.target.value
                                            }})
                                            //onFetchData()
                                        }} name="yr" id="" defaultValue={yr} className={clsx("form-control", {
                                            "form-control-lg":isPhone
                                        })}>
                                            {yearData().map((yr,i)=> <option key={i} value={yr}>{yr}</option>)}
                                            
                                        </select>
                                    </div>}
                                </div>
                            </form>

                            {!isStaff(props.user) && <div className={clsx("form-inline", {
                                "mt-2":isPhone,
                                "mt-3":isDesktopOrLaptop,
                            })}>
                            
                                <div className={clsx("row", {"w-100":isDesktopOrLaptop})}>
                                   <div className={clsx("form-group", {
                                        'col-6':isPhone,
                                        'col-3':isDesktopOrLaptop
                                    })}>
                                        <select defaultValue={''} onChange={(e)=>{
                                            if( isEmpty(e.target.value) ) {
                                                let args = omit(states.args, ['website_id'])
                                                setStates({
                                                    ...states, 
                                                    args:args
                                                })
                                            } else {
                                                setStates({
                                                    ...states, 
                                                    args:{...states.args,website_id:e.target.value
                                                }})
                                            }
                                            //onFetchData()
                                        }} name="website" id="" className={clsx("form-control", {
                                            "form-control-lg":isPhone,
                                            "w-100":isDesktopOrLaptop
                                        })}>
                                            <option value="">All Website</option>
                                            {!isEmpty(websites) && websites.map(item=><option key={item.id} value={item.id}>{upperCase(item.slug)}</option>)}
                                        </select>
                                    </div>
                                    
                                    <div className={clsx("form-group text-left", {
                                        'col-6':isPhone,
                                        'col-3':isDesktopOrLaptop,
                                    })}>
                                        <select defaultValue={''} onChange={(e)=>{
                                            if( isEmpty(e.target.value) ) {
                                                let args = omit(states.args, ['status'])
                                                setStates({
                                                    ...states, 
                                                    args:args
                                                })
                                            } else {
                                                setStates({
                                                    ...states, 
                                                    args:{...states.args,status:e.target.value
                                                }})
                                            }
                                            //onFetchData()
                                        }} name="status" id="" className={clsx("form-control", {
                                            "form-control-lg w-100":isPhone,
                                            "w-100":isDesktopOrLaptop,
                                        })}>
                                            <option value="">Status</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Accept</option>
                                            <option value="rejected">Rejected</option>
                                            <option value="followup">Follow-up</option>
                                        </select>
                                    </div>

                                    <div className={clsx("form-group", {
                                        "col-6":isPhone,
                                        "col-3":isDesktopOrLaptop,
                                    })}>
                                        <select defaultValue={curMo} onChange={(e)=>{
                                            if( isEmpty(e.target.value) ) {
                                                let args = omit(states.args, ['mo'])
                                                setStates({
                                                    ...states, 
                                                    args:args
                                                })
                                            } else {
                                                setStates({
                                                    ...states, 
                                                    args:{...states.args,mo:e.target.value
                                                }})
                                            }
                                            //onFetchData()
                                        }} name="mo" id="" className={clsx("form-control", {
                                            "form-control-lg":isPhone,
                                            "w-100":isDesktopOrLaptop,
                                        })}>
                                            <option value="">All Month</option>
                                            {monthData().map((mo,i)=> <option key={i} value={mo.key}>{mo.label}</option>)}
                                        </select>
                                    </div>

                                    <div className={clsx("form-group", {
                                        "col-6":isPhone,
                                        "col-3":isDesktopOrLaptop,
                                    })}>
                                        <select value={states.yr} onChange={(e)=>{
                                            setStates({
                                                ...states, 
                                                args:{...states.args,yr:e.target.value
                                            }})
                                            //onFetchData()
                                        }} name="yr" id="" defaultValue={yr} className={clsx("form-control", {
                                            "form-control-lg":isPhone,
                                            "w-100":isDesktopOrLaptop,
                                        })}>
                                             {yearData().map((yr,i)=> <option key={i} value={yr}>{yr}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>}
                        </div>

                    </div>                
                </div> 
            </div>

            {loading && <Preloader />}
            {!loading && <div className="table-responsive">
                {isStaff(props.user) ? <StaffTable 
                    user={props.user} 
                    list={list.data} 
                    loading={states.loadingNote} 
                    submitNote={onSubmitNote} 
                    count={list.count} 
                    args={states.args}
                    page={states.page}
                    pages={pages} 
                    tab={states.tab}
                    onPagePrev={onPagePrev} 
                    onPageNumber={onPageNumber} 
                    onPageNext={onPageNext}
                    onSetWorkStatus={onSetWorkStatus}
                    onSetBlacklistPhone={onSetBlacklistPhone}
                /> : 
                <AdminTable 
                    user={props.user} 
                    loading={states.loadingNote} 
                    submitNote={onSubmitNote} 
                    onDelete={onDelete} 
                    list={list.data} 
                    count={list.count} 
                    args={states.args}
                    page={states.page}
                    pages={pages}
                    onPagePrev={onPagePrev} 
                    onPageNumber={onPageNumber} 
                    onSendEmail={onSendEmail}
                    onPageNext={onPageNext} 
                    onSetBlacklistPhone={onSetBlacklistPhone}
                    sending={states.sending} /> }
            </div>}
        </>
    )
    
}

AppTable.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
    apps: state.apps,
    user: state.auth.user.user,
});

export default connect(
    mapStatetoProps,
    { fetchApplications, putNote, trashApp, putWorkStatus, sendFollowupEmail, addBlacklistIdentity, fetchWebsites }
)(AppTable); 
