import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom'
import { logoutUser, clearCurrentProfile } from '../../actions/auth';
import { createNotification, currency, isCashier, isSuperadmin } from '../../utils/helpers'
import { MdShoppingBasket, MdMonetizationOn, MdArrowForward, MdDashboard, MdAssignment, MdGroup, MdPerson } from 'react-icons/md'
import lang from '../../langs'
import { isNil } from 'lodash'

class Bottombar extends Component {


    static defaultProps = {
        cart: false
    }

    constructor(props) {
        super(props);
        this.onLogoutClick = this.onLogoutClick.bind(this)
    }

    onLogoutClick(e) {
        e.preventDefault();
        this.props.clearCurrentProfile();
        this.props.logoutUser();

        createNotification('info', lang('success.logout'))
    }

    render() {
        if (this.props.cart == true) {
            if (!isNil(this.props.products.cart)) {
                const { items, qty, total } = this.props.products.cart
                return (
                    <ul className="bottombar bg-white shadow-md p-0 nav justify-content-around">
                        <div style={{ width: "100%" }} className="row justify-content-around">
                            <li className="col text-left nav-item" style={{ padding: "0.65rem 0 0.65rem 1rem" }}>
                                <span className="font-weight-bolder pr-2"><MdShoppingBasket size="2em" color="#666" /> <span className="font-weight-bold text-danger">{currency(total)}</span></span>

                                <span style={{ fontSize: "95%" }} className="badge badge-pill badge-danger">{qty}</span>
                            </li>

                            <li style={{ paddingRight: 0 }} className="col-3 nav-item">
                                <Link replace={true} style={{ borderRadius: 0 }} to="/omzet/cart" className="btn btn-danger h-100">
                                    <MdArrowForward size="1.8em" color="white" />
                                </Link>

                            </li>
                        </div>
                    </ul>
                );
            }
        }

        return (
            <ul className="bottombar bg-white shadow-md p-0 nav justify-content-around">
                <div style={{ width: "100%" }} className="row no-gutters">

                    <li className="col nav-item">
                        <NavLink to="/dashboard" activeClassName="selected">
                            <MdDashboard size="2em" color="#666" />
                        </NavLink>
                    </li>

                    <li className="col nav-item">
                        <NavLink to="/transaction" activeClassName="selected">
                            <MdMonetizationOn size="2em" color="#666" />
                        </NavLink>
                    </li>

                    <li className="col nav-item">
                        <NavLink to="/product" activeClassName="selected">
                            <MdAssignment size="2em" color="#666" />
                        </NavLink>
                    </li>


                    {isSuperadmin(this.props.auth.user.user) && <li className="col nav-item">
                        <NavLink to="/user" activeClassName="selected">
                            <MdGroup size="2em" color="#666" />
                        </NavLink>
                    </li>}

                    <li className="col nav-item">
                        <NavLink to="/account" activeClassName="selected">
                            <MdPerson size="2em" color="#666" />
                        </NavLink>
                    </li>
                </div>
            </ul>
        );
    }
}

Bottombar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    cart: PropTypes.bool
};

const mapStatetoProps = state => ({
    auth: state.auth,
    products: state.products
});

export default connect(mapStatetoProps, { logoutUser, clearCurrentProfile })(Bottombar);