import { 
    FETCH_APPLICATIONS, 
    FILTER_APPLICATIONS, 
    SORT_APPLICATIONS, 
    FETCH_WEBSITES,
    GET_ERRORS, 
    PUT_NOTE_APPLICATION, 
    PUT_WORK_STATUS,
    DELETE_APPLICATION,
    SEND_FOLLOWUP_EMAIL,
    ADD_BLACKLIST_PHONE,
    REMOVE_BLACKLIST_PHONE,
    BLACKLIST_PHONES
} from "./types";
import { formLoadingStart, formLoadingFinish } from "./modal";
import rest from "../utils/rest";

export const fetchApplications = args => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .get("applications", {
                params: args
            })
            .then(res => {
                dispatch({
                    type: FETCH_APPLICATIONS,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
}


export const fetchWebsites = args => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get("websites", {
                params: args
            })
            .then(res => {
                dispatch({
                    type: FETCH_WEBSITES,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
    });
}

export const putNote = args => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .post("applications", args)
            .then(res => {
                dispatch({
                    type: PUT_NOTE_APPLICATION,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
};


export const putWorkStatus = args => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .post("applications", args)
            .then(res => {
                dispatch({
                    type: PUT_WORK_STATUS,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err.response);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
};


export const trashApp = (id, args) => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .delete("applications/" + id, args)
            .then(res => {
                dispatch({
                    type: DELETE_APPLICATION,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
};


export const sendFollowupEmail = id => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .get("send/email.followup/" + id)
            .then(res => {
                dispatch({
                    type: SEND_FOLLOWUP_EMAIL,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
};



export const addBlacklistIdentity = (id, params={}) => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .put("applications/blacklist/" + id, params)
            .then(res => {
                dispatch({
                    type: ADD_BLACKLIST_PHONE,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
};



export const removeBlacklistIdentity = id => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .delete("applications/blacklist/" + id)
            .then(res => {
                dispatch({
                    type: REMOVE_BLACKLIST_PHONE,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
};



export const blacklist = (args) => dispatch => {
    dispatch(formLoadingStart());
    return new Promise((resolve, reject) => {
        rest()
            .get("applications/blacklist", {params:args})
            .then(res => {
                dispatch({
                    type: BLACKLIST_PHONES,
                    payload: res.data
                });

                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                });

                reject(err);
            })
            .then(function () {
                dispatch(formLoadingFinish());
            });
    });
};

