import React, { Component, useCallback, useState } from "react"
import { MdComment, MdExpandLess, MdExpandMore, MdEmail, MdPhoneLocked } from "react-icons/md"
import classnames from "classnames"
import CryptoJS from "crypto-js"
import {
    createNotification,
    currency
} from "../../utils/helpers";
import { isEmpty, has, isNil, find } from "lodash";
import { FaTrash, FaForward, FaPaperPlane, FaExclamationCircle } from "react-icons/fa"
import Table from "./Table"
import MiniPreloader from "../ui/MiniPreloader"

// Define a default UI for filtering
const EmailFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Email address...`}
      />
    )
}

export default function AdminTable({ 
    list, 
    user, 
    count, 
    args, 
    page, 
    pages, 
    loading, 
    submitNote, 
    onDelete, 
    onPageNext, 
    onPageNumber, 
    onPagePrev,
    onSendEmail,
    onSetBlacklistPhone,
    sending
}) {
    
    const [edit, setEdit] = useState(0)
    const [baru, buatBaru] = useState(0)
    const [note, setNote] = useState("")
    const [newNote, addNewNote] = useState("")
    const pageSize = 30

    function onDeleteItem(id) {
        onDelete(id)
    }

    function isDataImage(qr_code){
        return (qr_code.match(/data:image/g) ? true : false)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Customer',
                columns: [
                    {
                        // Make an expander cell
                        Header: () => null, // No header
                        id: 'expander', // It needs an ID
                        Cell: ({ row }) => (
                            // Use Cell to render an expander for each row.
                            // We can use the getExpandedToggleProps prop-getter
                            // to build the expander.
                            <span {...row.getToggleRowExpandedProps()}>
                                {console.log("getExpandedToggleProps", row)}
                                {row.isExpanded ? <MdExpandLess size={24} color="#999" /> : <MdExpandMore size={24} color="#999" />}
                            </span>
                        ),
                    },
                    {
                        Header: 'Email',
                        accessor: (d) => d,
                        Cell: ({ cell: { value } }) => (
                            <>
                                <div className="font-weight-bold">{value.title}</div>
                                <div className=""><em>{value.full_name}</em></div>
                                <div className=""><em>{value.ip_address}</em></div>
                            </>
                        )
                    },
                    {
                        Header: 'Phone No.',
                        accessor: (d) => {
                            return d.phone_no ? d.phone_no : d.customer.phone_no
                        },
                    },
                ],
            },
            {
                Header: 'Info',
                columns: [
                    {
                        Header: 'S/N',
                        accessor: (d) => {
                            let parsed = CryptoJS.enc.Base64.parse(d.serial_number)
                            return CryptoJS.enc.Utf8.stringify(parsed)
                        },
                    },

                    {
                        id: "loan_amount",
                        Header: 'Loan Amount',
                        accessor: (d) => {
                            return currency(d.loan_amount)
                        },
                        Cell: ({ cell: { value } }) => <div className="text-right">{value}</div>
                    },

                    {
                        Header: 'Monthly Income',
                        accessor: (d) => {
                            return currency(d.monthly_income)
                        },
                        Cell: ({ cell: { value } }) => <div className="text-right">{value}</div>
                    },

                    {
                        id: "status",
                        Header: 'Status',
                        accessor: (d) => d,
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div className="text-center">
                                {value.status === 'approved' && <span style={{width:70}} className="badge badge-success">Approved</span>}
                                {value.status === 'pending' && <span style={{width:70}} className="badge badge-secondary">Pending</span>}
                                {value.status === 'rejected' && <span style={{width:70}} className="badge badge-danger">Rejected</span>}
                                <br/>
                                {value.blacklisted.email === true && <><span style={{width:70}} title="Email blacklisted" className="badge badge-danger">Email</span><br/></>} 
                                {value.blacklisted.phone_no === true && <span style={{width:70}} title="Phone Number blacklisted" className="badge badge-danger">Phone No.</span>}
                                {value.blacklisted.ip_address === true && <span style={{width:70}} title="IP Address blacklisted" className="badge badge-danger">Ip Address</span>}
                            </div>
                        )
                    },

                    {
                        id: "commentable",
                        Header: "#",
                        Cell: (row) => row.row.original.notes.length > 0 ? <MdComment size={18} title="Has comment" color="grey" /> : <span></span>
                    },

                    {
                        Header: 'Applied Date',
                        accessor: (d) => {
                            return d.created_date.short
                        },
                    },

                    {
                        Header: 'Action',
                        id:"delete",
                        accessor: (d) => {
                            return d.created_date.short
                        },
                        Cell: (row)=> (
                            <>
                                {(sending == row.row.original.id) ? <span>Loading...</span> : (<div>
                                    <span 
                                        className="btn btn-light mt-1 btn-sm"
                                        style={{cursor:'pointer'}}
                                        onClick={()=>onDeleteItem(row.row.original.id)}>
                                            <FaTrash title="Junk" size={10}  />
                                    </span> 
                                    <span 
                                        className={classnames('btn btn-success btn-sm mt-1', {
                                            'btn-light':row.row.original.send_followup == 0,
                                            'btn-success':row.row.original.send_followup == 1
                                        })}
                                        style={{cursor:'pointer'}}
                                        title={"Send Followup Email"}
                                        onClick={()=>onSendEmail(row.row.original.id, row.row.original.customer.email)}>
                                            <FaPaperPlane title="Send Followup Email" size={10} color={row.row.original.send_followup == 1 ? 'white' : 'green'} />
                                    </span> 

                                    <span 
                                        className={classnames('btn mt-1 btn-sm', {
                                            "btn-danger":(row.row.original.blacklisted.email || row.row.original.blacklisted.phone_no || row.row.original.blacklisted.ip_address),
                                            "btn-outline-danger":(row.row.original.blacklisted.email == false && row.row.original.blacklisted.phone_no == false && row.row.original.blacklisted.ip_address == false),
                                            
                                        })}
                                        disabled={
                                            row.row.original.blacklisted.email && 
                                            row.row.original.blacklisted.phone_no && 
                                            row.row.original.blacklisted.ip_address 
                                        }
                                        style={{cursor:'pointer'}}
                                        onClick={()=>onSetBlacklistPhone(row.row.original.id, {
                                            email:row.row.original.blacklisted.email,
                                            phone_no:row.row.original.blacklisted.phone_no,
                                            ip_address:row.row.original.blacklisted.ip_address
                                        })}>
                                            <FaExclamationCircle title="Blacklist" size={10} />
                                    </span> 
                                </div>)}
                            </>
                        )
                    },
                    
                ],
            },
        ],
        []
    )
    console.log("list", list)
    const data = React.useMemo(() => list, list)

    // Create a function that will render our row sub components
    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <div
                className={"row"}
                style={{
                    fontSize: '12px',
                   
                }}
            >
                {row.original.statuses.length>0 && <div className="col-12">
                    <table className="table table-striped table-highlight">
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Status</th>
                                <th>Followup Date</th>
                                <th>Accepted Date</th>
                                <th>Rejected Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {row.original.statuses.map((item, ind)=>
                            <tr>
                                <td>{item.company.name}</td>
                                <td>
                                    {item.status === 'approved' && <span className="badge badge-success">Approved</span>}
                                    {item.status === 'followup' && <span className="badge badge-secondary">Follow-up</span>}
                                    {item.status === 'rejected' && <span className="badge badge-danger">Rejected</span>}
                                </td>
                                <td>
                                    {item.followedup_date.raw ? <span>{item.followedup_date.short}</span> : '' }
                                </td>
                                <td>
                                    {item.approved_date.raw ? <span>{item.approved_date.short}</span> : '' }
                                </td>
                                <td>
                                    {item.rejected_date.raw ? <span>{item.rejected_date.short}</span> : '' }
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>}

                <div className="col-12">
                    <div className="row">
                        <div className="col-3" style={{ width: 200 }}>
                            {isDataImage(row.original.qr_code) ? <img src={row.original.qr_code} width={200} /> : <div dangerouslySetInnerHTML={{ __html: row.original.qr_code }} />}
                        </div>

                        <div className="col-8 mt-3">
                            {row.original.notes && !isEmpty(row.original.notes) && <div>
                                {row.original.notes.map((it,ix)=> (
                                    <div className="mb-2" style={{padding:15, background:'#eee', borderRadius:10}}>
                                        <div className={classnames({
                                            'd-none': edit === it.id
                                        })}>
                                            <p style={{
                                                fontSize:16,
                                                fontFamily:"Georgia"
                                            }}>{it.note}</p>
                                            <p style={{marginBottom:6}}> {isEmpty(it.updated_date) ? 'Added' : 'Last updated'} <em>{it.admin.username} at { isEmpty(it.updated_date) ? it.created_date.short : it.updated_date.short}</em></p>
                                            {it.admin.id === user.id && <button disabled={loading} type="button" onClick={() => {
                                                setEdit(it.id)
                                                setNote(it.note)
                                            }} className="btn btn-outline-secondary btn-sm"> {loading?'Loading...' : 'Edit'}
                                            </button>}
                                        </div>

                                        {edit === it.id && <div className="col-8 mt-3">
                                            <textarea cols="10" rows="5" placeholder="Enter note..." value={note} onChange={(e) => setNote(e.target.value)} className="form-control"></textarea>
                                            <button type="button" disabled={loading} onClick={() => {
                                                if (isEmpty(note)) {
                                                    createNotification("error", "Sorry")
                                                    return
                                                }
                                                submitNote(it.application_id, note)
                                                if (!loading) {
                                                    setEdit(0)
                                                    setNote("")
                                                }

                                            }} className="btn btn-info btn-sm mt-2">Submit Note</button>
                                            <button type="button" disabled={loading} onClick={() => {
                                                setEdit(0)
                                                setNote("")
                                            }} className="btn btn-outline-secondary btn-sm ml-2 mt-2"> Cancel
                                            </button>
                                        </div>}
                                    </div>
                                ))}
                            </div>}

                            {baru === row.original.id && <div>
                                <textarea cols="10" rows="5" placeholder="Enter note..." value={newNote} onChange={(e) => addNewNote(e.target.value)} className="form-control mt-2"></textarea>
                                <button type="button" disabled={loading} onClick={() => {
                                    if (isEmpty(newNote)) {
                                        createNotification("error", "Sorry")
                                        return
                                    }
                                    submitNote(row.original.id, newNote)
                                    if (!loading) {
                                        setEdit(0)
                                        buatBaru(0)
                                        addNewNote("")
                                    }
                                }} className="btn btn-info btn-sm mt-2">Submit Note</button>
                                <button type="button" disabled={loading} onClick={() => {
                                    buatBaru(0)
                                    addNewNote("")
                                }} className="btn btn-outline-secondary btn-sm ml-2 mt-2"> Cancel
                                </button>
                            </div>}

                            {( isEmpty(row.original.notes) || isNil(find(row.original.notes, ['admin_id', user.id])) ) && row.original.id !== baru && <div>
                                <button type="button" disabled={loading}  onClick={() => {
                                    buatBaru(row.original.id)
                                }} className="btn btn-success btn-sm mt-2"> {loading?'Loading...' : 'Add Note'}
                                </button>
                            </div>}

                        </div>
                
                    </div>
                </div>
            </div>
        ),
        [note, edit, newNote, baru, loading]
    )
    
    const countPages = Array.from(Array(pages).keys())
    //console.log("data", data)
    return (
        <>
            {count > 0 && Array.isArray(data) ? (<table className="table table-striped table-bordered">
                <Table className="-striped -bordered" columns={columns} data={data} renderRowSubComponent={renderRowSubComponent} />
            </table>) : null}
            {count > args.limit && <div className="Page navigation align-center mt-2">
                <ul className="pagination">
                    <li style={{marginRight:10}}>
                        <span style={{ lineHeight:2 }}>Page: {page} of {pages} </span>
                    </li>
                    <li className={classnames('page-item', {
                        'disabled':args.offset === 0
                    })}>
                        <a className="page-link" onClick={onPagePrev} href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>

                    {pages > 1 && countPages.map((i)=>(
                        <li className={classnames('page-item', {
                            'active':page === parseInt(i+1)
                        })}>
                            <a className="page-link" onClick={(e)=> {
                                e.preventDefault(); 
                                onPageNumber(parseInt(i+1))
                            }} href="#" aria-label="Next">
                                <span aria-hidden="true">{parseInt(i+1)}</span>
                            </a>
                        </li>
                    ))}

                    <li className={classnames('page-item', {
                        'disabled': (args.limit + args.offset) > count
                    })}>
                        <a className="page-link" onClick={onPageNext} href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </div>}
        </>
    )
}
